import {
  AcceptIcon,
  BookmarkIcon,
  Button,
  CallVideoIcon,
  ChevronEndIcon,
  ChevronStartIcon,
  CloseIcon,
  ContactGroupCallIcon,
  Divider,
  FilesPdfColoredIcon,
  FlagIcon,
  Flex,
  ItalicIcon,
  Loader,
  PollIcon,
  PresenterIcon,
  RetryIcon,
  ShiftActivityIcon,
  SurveyIcon,
  SyncIcon,
  Text,
  Tooltip,
  VolumeIcon,
} from "@fluentui/react-northstar";
import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useChartContext } from "../../../../../context/ChartContext";
import axios from "axios";
import { toast } from "react-toastify";
import { Card } from "antd";
import { HourglassOutlined } from "@ant-design/icons";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import ProgressChart from "../ProgressChart/ProgressChart";
import "./TeacherReportCard.scss";

const chartTypes = {
  attendance: "attendance",
  assignment: "assignment",
  event: "event",
  feedback: "feedback",
  booking: "booking",
};

const TeacherReportCard = (props) => {
  const { selectedTeacher } = useChartContext();
  const { t } = useTranslation();

  const translation = t("teacherReportCard");

  const [loadedInfo, setLoadedInfo] = React.useState(null);
  const [customFieldInfo, setCustomFieldInfo] = React.useState(null);
  const [selectedChart, setSelectedChart] = React.useState(
    chartTypes.attendance
  );
  const [loader, setLoader] = React.useState(false);

  const teacherCardRef = useRef(null);

  const downloadPDF = async () => {
    if (!teacherCardRef.current) return;

    const input = teacherCardRef.current;

    // Ensure all images are loaded before capturing
    const imgPromises = Array.from(input.querySelectorAll("img")).map((img) => {
      return new Promise((resolve) => {
        if (img.complete) resolve();
        else {
          img.onload = resolve;
          img.onerror = resolve; // Prevent blocking if an image fails
        }
      });
    });

    await Promise.all(imgPromises);

    try {
      const canvas = await html2canvas(input, { useCORS: true }); // Higher quality
      const imgData = canvas.toDataURL("image/png");

      const pdf = new jsPDF("p", "mm", "a4");
      const imgWidth = 190; // Adjust to fit A4
      const imgHeight = (canvas.height * imgWidth) / canvas.width;

      pdf.addImage(imgData, "PNG", 10, 10, imgWidth, imgHeight);
      pdf.save("teacher-card.pdf");
    } catch (error) {
      console.error("Error generating PDF:", error);
    }
  };

  const getInfo = (email) => {
    let d = new Date();

    setLoader(true);
    setLoadedInfo(null);
    setCustomFieldInfo(null);
    setSelectedChart(chartTypes.attendance);
    const payload = {
      LessonDate: [
        new Date(d.getFullYear(), d.getMonth() - 3, d.getDate(), 0, 0, 0, 0),
        new Date(d.getFullYear(), d.getMonth(), d.getDate(), 23, 59, 0, 0),
      ],
      TeacherName: email,
    };
    axios
      .post(
        `${process.env.REACT_APP_EP_URL}/api/admin/get-teacher-info/${email}${props.user.slug}`,
        payload,
        {
          headers: {
            Authorization: "Bearer " + props.user.accessToken,
          },
        }
      )
      .then((res) => {
        const response = res.data.result;
        const { assignment, feedback, event, info, chart, booking } = res.data;
        setLoadedInfo({
          ...response,
          Ausente: response?.Ausente ?? 0,
          Justificado: response?.Justificado ?? 0,
          Presente: response?.Presente ?? 0,
          Retardo: response?.Retardo ?? 0,
          TeacherEmailId: response?.TeacherData.AttendanceTakenBy ?? "",
          StudentName: response?.TeacherData?.ATBName ?? "",
          Total: response?.Total ?? 0,

          assignment: {
            total: assignment?.total ?? 0,
            reSubmit: assignment?.Resubmit ?? 0,
            completed: assignment?.Completed ?? 0,
            pending: assignment?.Pending ?? 0,
            gradesPending: assignment?.["Grades Pending"] ?? 0,
          },
          event: {
            registered: event?.registered ?? 0,
            bookmarked: event?.bookmarked ?? 0,
            organized: event?.organized ?? 0,
            total: event?.total ?? 0,
          },
          feedback: {
            total: feedback?.total ?? 0,
            poll: feedback?.poll ?? 0,
            text: feedback?.text ?? 0,
            audio: feedback?.audio ?? 0,
            video: feedback?.video ?? 0,
            mixed: feedback?.mixed ?? 0,
            any: feedback?.any ?? 0,
          },
          info: {
            ...info,
            attendanceAverage: info.attendanceAverage ?? 0,
            assignmentAverage: info.assignmentAverage ?? 0,
          },
          chart,
          booking,
        });
        setCustomFieldInfo(res.data.custom_field);
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
        toast.error(err?.response?.data?.message);
        console.log(err);

        if (err.response?.status === 401) {
          setAccDlg(true);
        }
      });
  };

  const changeRoleHandler = (email) => {
    return (
      <Button
        style={{ width: "fit-content" }}
        icon={<SyncIcon />}
        content={t("setting").teacher[6]}
        iconPosition="before"
        disabled={
          props.user.slug === "?slug=acvpreprod" ||
          props.user.slug === "?slug=acvapp"
        }
        onClick={() => {
          axios
            .post(
              `${process.env.REACT_APP_EP_URL}/api/admin/change-teacher-role-to-admin${props.user.slug}`,
              {
                email: email,
              },
              {
                headers: {
                  Authorization: "Bearer " + props.user.accessToken,
                },
              }
            )
            .then((res) => {
              getInfo(email);
            })
            .catch((err) => {
              toast.error(err?.response?.data?.message);
              console.log(err);
            });
        }}
      />
    );
  };

  const chartSelectHandler = (type) => {
    setSelectedChart(type);
  };

  const scrollButtonHandler = (isScrollToRight) => {
    document.getElementById("scroll-statictics-container").scrollBy({
      left: isScrollToRight ? 150 : -150,
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    if (selectedTeacher) {
      getInfo(selectedTeacher.key);
    }
  }, [selectedTeacher]);

  return (
    <Card ref={teacherCardRef}>
      <Flex
        gap="gap.medium"
        className="teacher-data-wrapper"
        wrap={props.isMobileView}
      >
        <Flex column className="teacher-image-info-wrapper" gap="gap.medium">
          <img
            src={selectedTeacher?.image}
            className="m-img"
            crossOrigin="anonymous"
          />
          {loadedInfo ? (
            <Flex column className="teacher-info-wrapper" gap="gap.smaller">
              <Flex gap="gap.small" wrap>
                <Text
                  content={`${translation.common.name}:`}
                  className="Karla fw6 tcap"
                />
                <Tooltip
                  content={selectedTeacher.header}
                  trigger={
                    <Text
                      content={selectedTeacher.header}
                      className="Karla tcap field-value"
                    />
                  }
                />
              </Flex>
              <Flex gap="gap.small" wrap>
                <Text
                  content={`${translation.common.account}:`}
                  className="Karla fw6 tcap"
                />
                <Tooltip
                  content={selectedTeacher.key}
                  trigger={
                    <Text
                      content={selectedTeacher.key}
                      className="Karla tlow field-value"
                    />
                  }
                />
              </Flex>

              <Flex gap="gap.small" wrap>
                <Text
                  content={`${translation.common.attendanceEffectiveness}:`}
                  className="Karla fw6 tcap"
                />
                <Text
                  content={
                    Number(loadedInfo?.info?.attendanceEffectiveness) > 0
                      ? loadedInfo?.info?.attendanceEffectiveness + " %"
                      : 0
                  }
                  className="Karla tcap"
                />
              </Flex>
              <Flex gap="gap.small" wrap>
                <Text
                  content={`${translation.common.assignmentsEffectiveness}:`}
                  className="Karla fw6 tcap"
                />
                <Text
                  content={
                    Number(loadedInfo?.info?.assignmentEffectiveness) > 0
                      ? loadedInfo?.info?.assignmentEffectiveness + " %"
                      : 0
                  }
                  className="Karla tcap"
                />
              </Flex>
              <Flex gap="gap.small" wrap>
                <Text
                  content={`${translation.common.bookingSessions}:`}
                  className="Karla fw6 tcap"
                />
                <Text
                  content={Number(loadedInfo?.booking?.meetings)}
                  className="Karla tcap"
                />
              </Flex>
              <Flex gap="gap.small" wrap>
                <Text
                  content={`${translation.common.weeklyClassHours}:`}
                  className="Karla fw6 tcap"
                />
                <Text
                  content={
                    loadedInfo?.info?.weeklyClassSummary?.weeklyClassHours || 0
                  }
                  className="Karla fw6 tcap"
                />
              </Flex>
              <div className="Karla tcap">
                {Object.entries(
                  loadedInfo?.info?.weeklyClassSummary?.classHours || {}
                )?.length > 0 ? (
                  <Flex column className="subjects-wrapper">
                    {Object.entries(
                      loadedInfo.info.weeklyClassSummary.classHours
                    ).map(([subject, hours], i) => (
                      <Flex key={`subject-${i}`}>
                        <span>{subject}</span>
                        <span>{` : ${parseInt(hours) || 0}${
                          hours > 0 ? "h" : ""
                        }`}</span>
                      </Flex>
                    ))}
                  </Flex>
                ) : (
                  <Text
                    content={t("setting").student.info[6]}
                    className="Karla tcap"
                  />
                )}
              </div>

              <Flex hAlign="center" style={{ marginTop: "20px" }}>
                {props.user.actualRole === "SuperAdmin"
                  ? changeRoleHandler(selectedTeacher.key)
                  : null}
              </Flex>
            </Flex>
          ) : (
            ""
          )}

          {loadedInfo?.StudentData ? (
            <Flex gap="gap.large" wrap fill>
              <Flex column>
                <Divider content={t("setting").student.attendance[1]} />
                {customFieldInfo?.map((cf) => {
                  return (
                    <Text
                      content={
                        <>
                          <FlagIcon outline className="pb2 pr2" />
                          {/* <Status state={cf.IsEnable?"info":"unknown"} className="pb2 pr2"/> */}
                          <Text
                            content={" " + cf.Name}
                            color="brand"
                            className="fw6"
                            disabled={!cf.IsEnable}
                          />{" "}
                          - {loadedInfo.StudentData["CF_" + cf.CustomFieldID]}
                        </>
                      }
                      className="Karla m1 tcap"
                      key={cf.CustomFieldID}
                      disabled={!cf.IsEnable}
                    />
                  );
                })}
              </Flex>
            </Flex>
          ) : null}
        </Flex>
        <Flex column className="info-chart-wrapper">
          {loadedInfo ? (
            <>
              <Flex vAlign="center" hAlign="center">
                {/* <div className="scroll-button-wrapper">
                  <Button
                    className="scroll-button"
                    onClick={() => {
                      scrollButtonHandler(false);
                    }}
                  >
                    <ChevronStartIcon size="large" />
                  </Button>
                </div> */}
                <div
                  className="statictics-container"
                  id="scroll-statictics-container"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <Flex
                    className="statictics-wrapper"
                    gap="gap.large"
                    space="between"
                    fill
                  >
                    <Flex column>
                      <Divider
                        content={t("setting")?.student?.attendance[0]}
                        onClick={() =>
                          chartSelectHandler(chartTypes.attendance)
                        }
                        className={`selectable-option ${
                          selectedChart === chartTypes.attendance
                            ? "active-chart"
                            : ""
                        }`}
                      />
                      <Text
                        content={
                          <Flex gap="gap.smaller">
                            <PollIcon outline className="pb2 pr2" />
                            <Text
                              content={t("setting").student.attendance[2]}
                              color="brand"
                              className="fw6"
                            />{" "}
                            - {loadedInfo.Total}
                          </Flex>
                        }
                        className="Karla m1 tcap"
                      />
                      <Text
                        content={
                          <Flex gap="gap.smaller">
                            <AcceptIcon outline className="pb2 pr2" />
                            <Text
                              content={t("setting").student.attendance[3]}
                              color="brand"
                              className="fw6"
                            />{" "}
                            - {parseInt(loadedInfo.Presente)}
                          </Flex>
                        }
                        className="Karla m1 tcap"
                      />
                      <Text
                        content={
                          <Flex gap="gap.smaller">
                            <CloseIcon outline className="pb2 pr2" />
                            <Text
                              content={t("setting").student.attendance[4]}
                              color="brand"
                              className="fw6"
                            />{" "}
                            - {parseInt(loadedInfo.Ausente)}
                          </Flex>
                        }
                        className="Karla m1 tcap"
                      />
                      <Text
                        content={
                          <Flex gap="gap.smaller">
                            <ShiftActivityIcon outline className="pb2 pr2" />
                            <Text
                              content={t("setting").student.attendance[5]}
                              color="brand"
                              className="fw6"
                            />{" "}
                            - {parseInt(loadedInfo.Retardo)}
                          </Flex>
                        }
                        className="Karla m1 tcap"
                      />
                      <Text
                        content={
                          <Flex gap="gap.smaller">
                            <SurveyIcon outline className="pb2 pr2" />
                            <Text
                              content={t("setting").student.attendance[6]}
                              color="brand"
                              className="fw6"
                            />{" "}
                            - {parseInt(loadedInfo.Justificado)}
                          </Flex>
                        }
                        className="Karla m1 tcap"
                      />
                    </Flex>

                    <Flex column>
                      <Divider
                        content={t("setting").student.assignment[0]}
                        onClick={() =>
                          chartSelectHandler(chartTypes.assignment)
                        }
                        className={`selectable-option ${
                          selectedChart === chartTypes.assignment
                            ? "active-chart"
                            : ""
                        }`}
                      />
                      <Text
                        content={
                          <Flex gap="gap.smaller">
                            <PollIcon outline className="pb2 pr2" />
                            <Text
                              content={t("setting").student.assignment[1]}
                              color="brand"
                              className="fw6"
                            />{" "}
                            - {loadedInfo?.assignment?.total || 0}
                          </Flex>
                        }
                        className="Karla m1 tcap"
                      />
                      <Text
                        content={
                          <Flex gap="gap.smaller">
                            <AcceptIcon outline className="pb2 pr2" />
                            <Text
                              content={t("setting").student.assignment[2]}
                              color="brand"
                              className="fw6"
                            />{" "}
                            - {parseInt(loadedInfo?.assignment?.completed || 0)}
                          </Flex>
                        }
                        className="Karla m1 tcap"
                      />
                      <Text
                        content={
                          <Flex gap="gap.smaller">
                            <RetryIcon outline className="pb2 pr2" />
                            <Text
                              content={t("setting").student.assignment[3]}
                              color="brand"
                              className="fw6"
                            />{" "}
                            - {parseInt(loadedInfo?.assignment?.reSubmit || 0)}
                          </Flex>
                        }
                        className="Karla m1 tcap"
                      />
                      <Text
                        content={
                          <Flex gap="gap.smaller">
                            <ShiftActivityIcon outline className="pb2 pr2" />
                            <Text
                              content={t("setting").student.assignment[4]}
                              color="brand"
                              className="fw6"
                            />{" "}
                            - {parseInt(loadedInfo?.assignment?.pending || 0)}
                          </Flex>
                        }
                        className="Karla m1 tcap"
                      />
                      <Text
                        content={
                          <Flex gap="gap.smaller">
                            <SurveyIcon outline className="pb2 pr2" />
                            <Text
                              content={t("setting").student.assignment[5]}
                              color="brand"
                              className="fw6"
                            />{" "}
                            -{" "}
                            {parseInt(
                              loadedInfo?.assignment?.gradesPending || 0
                            )}
                          </Flex>
                        }
                        className="Karla m1 tcap"
                      />
                    </Flex>
                    <Flex column>
                      <Divider
                        content={t("setting").student.event[0]}
                        onClick={() => chartSelectHandler(chartTypes.event)}
                        className={`selectable-option ${
                          selectedChart === chartTypes.event
                            ? "active-chart"
                            : ""
                        }`}
                      />
                      <Text
                        content={
                          <Flex gap="gap.smaller">
                            <AcceptIcon outline className="pb2 pr2" />
                            <Text
                              content={t("setting").student.event[2]}
                              color="brand"
                              className="fw6"
                            />{" "}
                            - {parseInt(loadedInfo?.event?.registered || 0)}
                          </Flex>
                        }
                        className="Karla m1 tcap"
                      />
                      <Text
                        content={
                          <Flex gap="gap.smaller">
                            <BookmarkIcon outline className="pb2 pr2" />
                            <Text
                              content={t("setting").student.event[3]}
                              color="brand"
                              className="fw6"
                            />{" "}
                            - {parseInt(loadedInfo?.event?.bookmarked || 0)}
                          </Flex>
                        }
                        className="Karla m1 tcap"
                      />
                      <Text
                        content={
                          <Flex gap="gap.smaller">
                            <PresenterIcon outline className="pb2 pr2" />
                            <Text
                              content={t("setting").student.event[4]}
                              color="brand"
                              className="fw6"
                            />{" "}
                            - {parseInt(loadedInfo.event.organized)}
                          </Flex>
                        }
                        className="Karla m1 tcap"
                      />
                    </Flex>

                    <Flex column>
                      <Divider
                        content={t("setting").student.feedback[0]}
                        onClick={() => chartSelectHandler(chartTypes.feedback)}
                        className={`selectable-option ${
                          selectedChart === chartTypes.feedback
                            ? "active-chart"
                            : ""
                        }`}
                      />
                      <Text
                        content={
                          <Flex gap="gap.smaller">
                            <PollIcon outline className="pb2 pr2" />
                            <Text
                              content={t("setting").student.feedback[1]}
                              color="brand"
                              className="fw6"
                            />{" "}
                            - {loadedInfo.feedback.total}
                          </Flex>
                        }
                        className="Karla m1 tcap"
                      />
                      <Text
                        content={
                          <Flex gap="gap.smaller">
                            <PollIcon outline className="pb2 pr2" />
                            <Text
                              content={t("setting").student.feedback[2]}
                              color="brand"
                              className="fw6"
                            />{" "}
                            - {parseInt(loadedInfo.feedback.poll)}
                          </Flex>
                        }
                        className="Karla m1 tcap"
                      />
                      <Text
                        content={
                          <Flex gap="gap.smaller">
                            <ItalicIcon outline className="pb2 pr2" />
                            <Text
                              content={t("setting").student.feedback[3]}
                              color="brand"
                              className="fw6"
                            />{" "}
                            - {parseInt(loadedInfo.feedback.text)}
                          </Flex>
                        }
                        className="Karla m1 tcap"
                      />
                      <Text
                        content={
                          <Flex gap="gap.smaller">
                            <VolumeIcon outline className="pb2 pr2" />
                            <Text
                              content={t("setting").student.feedback[4]}
                              color="brand"
                              className="fw6"
                            />{" "}
                            - {parseInt(loadedInfo.feedback.audio)}
                          </Flex>
                        }
                        className="Karla m1 tcap"
                      />
                      <Text
                        content={
                          <Flex gap="gap.smaller">
                            <CallVideoIcon outline className="pb2 pr2" />
                            <Text
                              content={t("setting").student.feedback[5]}
                              color="brand"
                              className="fw6"
                            />{" "}
                            - {parseInt(loadedInfo.feedback.video)}
                          </Flex>
                        }
                        className="Karla m1 tcap"
                      />
                    </Flex>

                    {/* Booking data */}

                    <Flex column>
                      <Divider
                        content={translation.booking.title}
                        onClick={() => chartSelectHandler(chartTypes.booking)}
                        className={`selectable-option ${
                          selectedChart === chartTypes.booking
                            ? "active-chart"
                            : ""
                        }`}
                      />
                      <Text
                        content={
                          <Flex gap="gap.smaller">
                            <HourglassOutlined className="pb2 pr2" />
                            <Text
                              content={translation.booking.freeSlots}
                              color="brand"
                              className="fw6"
                            />{" "}
                            - {loadedInfo?.booking?.total || 0}
                          </Flex>
                        }
                        className="Karla m1 tcap"
                      />
                      <Text
                        content={
                          <Flex gap="gap.smaller">
                            <ContactGroupCallIcon outline className="pb2 pr2" />
                            <Text
                              content={translation.booking.meetings}
                              color="brand"
                              className="fw6"
                            />{" "}
                            - {parseInt(loadedInfo?.booking?.meetings || 0)}
                          </Flex>
                        }
                        className="Karla m1 tcap"
                      />
                      <Text
                        content={
                          <Flex gap="gap.smaller">
                            <AcceptIcon outline className="pb2 pr2" />
                            <Text
                              content={translation.booking.effectiveness}
                              color="brand"
                              className="fw6"
                            />{" "}
                            -{" "}
                            {parseInt(loadedInfo?.booking?.effectiveness || 0)}{" "}
                            %
                          </Flex>
                        }
                        className="Karla m1 tcap"
                      />
                      <Text
                        content={
                          <Flex gap="gap.smaller">
                            <BookmarkIcon outline className="pb2 pr2" />
                            <Text
                              content={translation.booking.favorites}
                              color="brand"
                              className="fw6"
                            />{" "}
                            - {parseInt(loadedInfo?.booking?.favorites || 0)}
                          </Flex>
                        }
                        className="Karla m1 tcap"
                      />
                      <Text
                        content={
                          <Flex gap="gap.smaller">
                            <PresenterIcon outline className="pb2 pr2" />
                            <Text
                              content={translation.booking.reviews}
                              color="brand"
                              className="fw6"
                            />{" "}
                            - {parseInt(loadedInfo?.booking?.reviews || 0)}
                          </Flex>
                        }
                        className="Karla m1 tcap"
                      />
                    </Flex>
                  </Flex>
                </div>
                {/* <div className="scroll-button-wrapper">
                  <Button
                    className="scroll-button"
                    onClick={() => {
                      scrollButtonHandler(true);
                    }}
                  >
                    <ChevronEndIcon size="large" />
                  </Button>
                </div> */}
              </Flex>

              <ProgressChart
                selectedChart={selectedChart}
                data={loadedInfo.chart}
                chartTypes={chartTypes}
                downloadPDF={downloadPDF}
              />
            </>
          ) : (
            loader && (
              <Flex fill hAlign="center" vAlign="center" column>
                <Loader size="small" />
              </Flex>
            )
          )}
        </Flex>
      </Flex>
    </Card>
  );
};

export default TeacherReportCard;
