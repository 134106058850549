import * as jose from "jose";

export const generateJitsiToken = async (
  userEmail,
  userName,
  roomName,
  userId,
  isTeacher
) => {
  const now = Date.now();
  const payload = {
    aud: "wb-jitsi",
    iss: "wb-jitsi",
    sub: "channels.acv.app",
    room: roomName,
    context: {
      user: {
        email: userEmail,
        name: userName,
        id: userId,
        moderator: isTeacher,
        affiliation: isTeacher ? "owner" : "member",
      },
      group: "channels.acv.app",
      features: {
        livestreaming: isTeacher,
        recording: isTeacher,
        transcription: isTeacher,
        "outbound-call": isTeacher,
        "kick-participant": isTeacher,
      },
    },
    moderator: isTeacher,
    exp: Math.floor((now + 3600000) / 1000),
    iat: Math.floor(now / 1000),
    nbf: Math.floor(now / 1000),
  };

  console.log("Generating token with payload:", payload);

  const secret = new TextEncoder().encode(
    "3919e0a61b0fdc081f0419d329ab769bd23d04e39c2768e24c38b65d9d6be6c9"
  );

  try {
    const token = await new jose.SignJWT(payload)
      .setProtectedHeader({ alg: "HS256", typ: "JWT" })
      .sign(secret);

    // console.log("Token JWT generated:", {
    //   tokenPreview: token.substring(0, 50) + "...",
    //   decodedPayload: JSON.parse(atob(token.split(".")[1])),
    // });

    return token;
  } catch (error) {
    console.error("Error generando token:", error);
    throw error;
  }
};
