import React from "react";
import ChartRouter from "./ChartRouter";
import ChartContextProvider from "../../context/ChartContext";

const ChartsWrapper = (data) => {
  return (
    <ChartContextProvider {...data}>
      <ChartRouter {...data} />
    </ChartContextProvider>
  );
};

export default ChartsWrapper;
