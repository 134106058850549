import { JitsiMeeting } from "@jitsi/react-sdk";
import React, { useCallback, useEffect, useRef, useState } from "react";
// import {
//   CreateStreaming,
//   deleteStreaming,
// } from "../excalidraw-app/api/storeElementData";
import { generateJitsiToken } from "./JitsiAuth";
import { useAudioChannel } from "../../../context/AudioChannelContext";

const AudioChannel = ({ stream, user }) => {
  const { joinChannelName, isJoining, setIsJoining } = useAudioChannel();

  const [connectionType, setConnectionType] = useState("audio");
  const [newStreamId, setNewStreamId] = useState(null);
  const [showJitsi, setShowJitsi] = useState(false);
  const [jitsiConfig, setJitsiConfig] = useState({
    startWithAudioMuted: false,
    startWithVideoMuted: false,
    disableVideo: false,
    prejoinPageEnabled: false,
    hideConferenceSubject: true,
    hideConferenceTimer: true,
    disablePolls: true,
    disableReactions: true,
    toolbarButtons: ["microphone", "camera", "hangup"],
    disableBrandingWatermark: true,
    disableDeepLinking: true,
    dynamicBrandingUrl: null,
    defaultLanguage: "en",
    enableClosePage: true,
    enableNoisyMicDetection: true,
    enableNoAudioDetection: true,
    enableLobby: false,
    notifications: [],
    p2p: {
      enabled: false,
    },
  });

  const [jitsiStatus, setJitsiStatus] = useState("disconnected");
  const [participantCount, setParticipantCount] = useState(0);
  const jitsiApiRef = useRef(null);
  const [roomName, setRoomName] = useState("wb-jitsi-anonymous");

  const newStreamIdRef = useRef(newStreamId);
  const handleParticipantLeft = () => {
    setParticipantCount((prev) => Math.max(0, prev - 1));
  };

  useEffect(() => {
    // Attach function to `window`
    window.handleCloseJitsiStream = handleCloseJitsiStream;

    return () => {
      // Cleanup: Remove function when component unmounts
      delete window.handleCloseJitsiStream;
    };
  }, []);

  useEffect(() => {
    newStreamIdRef.current = newStreamId;
  }, [newStreamId]);

  const createStream = async (roomName, type) => {
    try {
      //   const lessonId = await getLessonId();
      //   const slug =
      //     new URLSearchParams(window.location.search).get("slug") || "";
      //   const body = {
      //     lessonId,
      //     roomName,
      //     type,
      //   };
      //   const response = await CreateStreaming(body, slug);
      //   if (response.result) {
      //     setNewStreamId(response.result.id);
      //   }
      // window.parent.postMessage(
      //   {
      //     type: "IS_START_STREAMING",
      //     session: true,
      //     lessonId: await getLessonId(),
      //   },
      //   `${process.env.REACT_APP_PARENT_APP}`
      // );
    } catch (error) {
      console.error("Error while creating stream:", error);
    }
  };
  const deleteStream = useCallback(async () => {
    try {
      const slug =
        new URLSearchParams(window.location.search).get("slug") || "";

      // Use the latest value from the ref
      const currentStreamId = newStreamIdRef.current;

      if (currentStreamId) {
        // const response = await deleteStreaming(currentStreamId, slug);
      }
      // window.parent.postMessage(
      //   {
      //     type: "IS_START_STREAMING",
      //     session: false,
      //     lessonId: await getLessonId(),
      //   },
      //   `${process.env.REACT_APP_PARENT_APP}`,
      // );
    } catch (error) {
      console.error("Error while deleting stream:", error);
    }
  }, []);

  const handleCloseJitsiStream = () => {
    setShowJitsi(false);
    setConnectionType(undefined);
    setJitsiStatus("disconnected");
    setIsJoining(false);
    // setAppState({ stream: null });
    // setLiveStreamShowPopover(false);
    deleteStream();
    // const isStudent = JSON.parse(localStorage.getItem("S") || "{}");
    // if (isStudent) {
    //   window.parent.postMessage(
    //     { type: "ZOOM_IN_OUT", isZoom: false },
    //     `${process.env.REACT_APP_PARENT_APP}`
    //   );
    //   window.parent.postMessage(
    //     {
    //       type: "IS_START_STREAMING",
    //     },
    //     `${process.env.REACT_APP_PARENT_APP}`
    //   );
    // }
  };

  useEffect(() => {
    if (isJoining) {
      if (stream === "audio") {
        handleToggleAudio();
      }
    }
    return () => {
      if (isJoining) {
        deleteStream();
      }
    };
  }, [stream, isJoining]);

  const handleToggleAudio = async () => {
    if (!showJitsi || connectionType !== "audio") {
      setConnectionType("audio");
      setJitsiStatus("connecting");
      try {
        const isStudent = user.actualRole === "Student" ? true : false;
        // const user = JSON.parse(localStorage.getItem("user") || "{}");
        // const schoolName = user.schoolInfo.School.split(" ")
        //   .map((word) => word.charAt(0).toUpperCase())
        //   .join("");
        // const lessonData = localStorage.getItem("lesson");
        // const parsedLesson = JSON.parse(lessonData || "{}");
        const className = `${joinChannelName}`.replace(/\s+/g, "");

        // Establecer el nombre de la sala directamente aquí
        const audioRoomName = className.trim().replace(/\s+/g, "_");
        // .replace(/[^a-z0-9_]/g, "");

        setRoomName(audioRoomName);
        //  api calling
        if (!isStudent) {
          createStream(audioRoomName, "audio");
        }
        //get account info from local storage
        const account = user;

        const token = await generateJitsiToken(
          account.username,
          account.name || "Usuario",
          audioRoomName,
          account.localAccountId,
          isStudent ? false : true //isTeacher
        );

        if (!token) {
          throw new Error("No se pudo generar el token JWT");
        }

        setJitsiConfig((prev) => ({
          ...prev,
          jwt: token,
          startWithAudioMuted: false,
          startWithVideoMuted: true,
          disableVideo: true,
          prejoinPageEnabled: false,
          hideConferenceSubject: true,
          hideConferenceTimer: true,
          disablePolls: true,
          disableReactions: true,
          toolbarButtons: isStudent ? ["microphone"] : ["microphone", "hangup"],
          defaultLogoUrl: "",
          interfaceConfigOverwrite: {
            TOOLBAR_BUTTONS: isStudent
              ? ["microphone"]
              : ["microphone", "hangup"],
            HIDE_INVITE_MORE_HEADER: true,
            DEFAULT_REMOTE_DISPLAY_NAME: "",
            SHOW_JITSI_WATERMARK: false,
            SHOW_WATERMARK_FOR_GUESTS: false,
            SHOW_BRAND_WATERMARK: false,
            SHOW_POWERED_BY: false,
            GENERATE_ROOMNAMES_ON_WELCOME_PAGE: false,
            DISPLAY_WELCOME_PAGE_CONTENT: false,
            DISPLAY_WELCOME_PAGE_TOOLBAR_ADDITIONAL_CONTENT: false,
            SETTINGS_SECTIONS: [],
            VIDEO_LAYOUT_FIT: "nocrop",
            VERTICAL_FILMSTRIP: false,
            CLOSE_PAGE_GUEST_HINT: false,
            SHOW_PROMOTIONAL_CLOSE_PAGE: false,
            RECENT_LIST_ENABLED: false,
            ENABLE_FEEDBACK_ANIMATION: false,
            DISABLE_FOCUS_INDICATOR: true,
            DISABLE_VIDEO_BACKGROUND: true,
            DISABLE_DOMINANT_SPEAKER_INDICATOR: true,
            DISABLE_TRANSCRIPTION_SUBTITLES: true,
            DISABLE_RINGING: true,
            DISABLE_JOIN_LEAVE_NOTIFICATIONS: true,
            MOBILE_APP_PROMO: false,
            MAXIMUM_ZOOMING_COEFFICIENT: 1,
          },
        }));

        setShowJitsi(true);
      } catch (error) {
        console.error("Error al configurar Jitsi:", error);
        setJitsiStatus("disconnected");
      }
    } else {
      handleCloseJitsiStream();
    }
  };

  if (!isJoining) {
    return null;
  }

  return (
    <>
      <div className="jitsi-audio">
        {showJitsi && (
          <div
            style={{
              position: "fixed",
              left: "20px",
              bottom: "20px",
              width: "300px",
              height: "200px",
              background: "white",
              borderRadius: "8px",
              boxShadow: "0 2px 10px rgba(0,0,0,0.1)",
              overflow: "hidden",
              zIndex: 1000,
            }}
          >
            <JitsiMeeting
              domain="channels.acv.app"
              roomName={roomName}
              jwt={jitsiConfig.jwt}
              configOverwrite={jitsiConfig}
              getIFrameRef={(iframeRef) => {
                if (iframeRef) {
                  const iframe = iframeRef;
                  iframe.style.width = "100%";
                  iframe.style.height = "100%";
                  iframe.style.border = "none";
                }
              }}
              onApiReady={(externalApi) => {
                console.log("[JITSI] API ready");
                jitsiApiRef.current = externalApi;

                externalApi.addListener("videoConferenceJoined", (data) => {
                  console.log("[JITSI] Joined conference:", data);
                  setJitsiStatus("connected");
                });

                externalApi.addListener("participantJoined", (data) => {
                  console.log("[JITSI] Participant joined:", data);
                  setParticipantCount((prev) => prev + 1);
                });

                externalApi.addListener(
                  "participantLeft",
                  handleParticipantLeft
                );

                externalApi.addListener("connectionFailed", (error) => {
                  console.error("[JITSI] Connection failed:", error);
                  setJitsiStatus("disconnected");
                });
                externalApi.addListener("readyToClose", () => {
                  handleCloseJitsiStream();
                });
              }}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default AudioChannel;
