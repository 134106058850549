import React, { createContext, useState, useContext } from "react";

// Create the Modal Context
const ModalContext = createContext();

// Modal Provider Component
export const AudioChannelContext = ({ children }) => {
  const [modals, setModals] = useState({
    audioChannel: false,
    onDemand: false,
    directoryChannel: false,
    shareChannel: false,
    channelPassword: false,
    subChannelPassword: false,
    passwordModal: false,
    joinChannelModal: false,
  });
  const [channelData, setChannelData] = useState([]);
  const [editChannelId, setEditChannelId] = useState(null);
  const [isJoining, setIsJoining] = useState(false);
  const [joinChannelName, setJoinChannelName] = useState(null);
  const openChannelModal = (modalName) => {
    setModals({ ...modals, [modalName]: true });
  };

  const closeModal = (modalName) => {
    setModals({ ...modals, [modalName]: false });
  };

  const switchModals = (closeModalName, openChannelModalName) => {
    setModals({
      ...modals,
      [closeModalName]: false,
      [openChannelModalName]: true,
    });
  };

  return (
    <ModalContext.Provider
      value={{
        modals,
        openChannelModal,
        closeModal,
        switchModals,
        channelData,
        setChannelData,
        editChannelId,
        setEditChannelId,
        joinChannelName,
        setJoinChannelName,
        isJoining,
        setIsJoining,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};

// Hook to use Modal Context
export const useAudioChannel = () => {
  const context = useContext(ModalContext);
  if (!context) {
    throw new Error("useModal must be used within a AudioChannelContext");
  }
  return context;
};
