import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  IconButton,
  TextField,
  Box,
  Typography,
  Collapse,
  Chip,
} from "@mui/material";
import {
  Close as CloseIcon,
  PersonAdd as JoinIcon,
  Share as ShareIcon,
  Edit as EditIcon,
  Search as SearchIcon,
  ExpandMore,
  ExpandLess,
  Public as PublicIcon,
  Lock as PrivateIcon,
} from "@mui/icons-material";
import { ShareChannelModal } from "../ShareChannel";
import { useAudioChannel } from "../../../context/AudioChannelContext";
import { getChannel } from "../../../Assets/api/api";
import { PasswordModal } from "../PasswordModal/PasswordModal";
import { LoadingButton } from "@mui/lab";

export const DirectoryChannelModal = ({ user }) => {
  const {
    modals,
    closeModal,
    switchModals,
    channelData,
    setChannelData,
    openChannelModal,
    setEditChannelId,
    setJoinChannelName,
    setIsJoining,
    isJoining
  } = useAudioChannel();

  const [searchTerm, setSearchTerm] = useState("");
  const [filteredChannels, setFilteredChannels] = useState([]);
  const [expandedRows, setExpandedRows] = useState({});
  const [channelForPasswordModal, setChannelForPasswordModal] = useState(null);
  const [channelType, setChannelType] = useState("");
  const [channelId, setChannelId] = useState("");
  const [isPublic, setIsPublic] = useState(false);
  const [channelName, setChannelName] = useState("");
  const [loading, setLoading] = useState(false);
  // Fetch Channels
  useEffect(() => {
    const fetchChannels = async () => {
      try {
        setLoading(true);
        const response = await getChannel(user.slug);
        setChannelData(response.result || []);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching channels:", error);
      }
    };
    modals.directoryChannel && fetchChannels();
  }, [modals.directoryChannel]);

  // Update Filtered Channels when Data Changes
  useEffect(() => {
    setTimeout(() => {
      setFilteredChannels(channelData);
    }, 0);
  }, [channelData]);

  // Search Channels
  const handleSearch = () => {
    if (!searchTerm.trim()) {
      setFilteredChannels(channelData);
      return;
    }

    const lowerCaseSearch = searchTerm.toLowerCase();

    const results = channelData.filter((channel) => {
      // Check if the main channel matches the search term
      const isMainChannelMatch =
        channel.name.toLowerCase().includes(lowerCaseSearch) ||
        channel.owner?.toLowerCase().includes(lowerCaseSearch);

      // Check if any of the subchannels match the search term
      const isSubChannelMatch = channel.SubChannels?.some((sub) =>
        sub.name.toLowerCase().includes(lowerCaseSearch)
      );

      return isMainChannelMatch || isSubChannelMatch;
    });

    setFilteredChannels(results);
  };

  // Toggle SubChannel Row
  const toggleRow = (channelId) => {
    setExpandedRows((prev) => ({
      ...prev,
      [channelId]: !prev[channelId],
    }));
  };

  // Handle Join Channel

  const handleJoinChannel = (channel, type) => {
    if (channel.privacy === "public") {
      setIsJoining(true);
      setJoinChannelName(channel.name);
      closeModal("directoryChannel");
    } else if (channel.privacy === "private") {
      switchModals("directoryChannel", "passwordModal");
      setChannelForPasswordModal(channel);
      setChannelType(type);
    }
  };

  // Handle Share Channel
  const handleShareChannel = (channel) => {
    setChannelId(channel.id);
    setIsPublic(channel.privacy === "public");
    setChannelType(channel.SubChannels?.length > 0 ? "channel" : "subchannel");
    setChannelName(channel.name);
    switchModals("directoryChannel", "shareChannel");
  };

  // Handle Edit Channel
  const handleEditChannel = (channel) => {
    switchModals("directoryChannel", "onDemand");
    setEditChannelId(channel.id);
  };

  return (
    <>
      <Dialog open={modals.directoryChannel} maxWidth="lg" fullWidth>
        <DialogTitle>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            sx={{ mb: 1 }}
          >
            <Typography variant="h5" fontWeight="bold">
              Directory of Channels
            </Typography>
            <IconButton
              onClick={() => closeModal("directoryChannel")}
              size="large"
              sx={{ color: "#494B83" }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>

        <DialogContent>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            sx={{ mb: 3 }}
          >
            <Box
              display="flex"
              alignItems="center"
              gap={1}
              sx={{ width: "60%" }}
            >
              <TextField
                fullWidth
                size="small"
                placeholder="Search channels by name or owner..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                onKeyDown={(e) => e.key === "Enter" && handleSearch()}
                InputProps={{
                  startAdornment: (
                    <SearchIcon sx={{ mr: 1, color: "#494B83" }} />
                  ),
                }}
                variant="outlined"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "8px",
                  },
                }}
              />
              <Button
                variant="contained"
                onClick={handleSearch}
                sx={{
                  backgroundColor: "#494B83",
                  borderRadius: "8px",
                  "&:hover": {
                    backgroundColor: "#363761",
                  },
                }}
              >
                Search
              </Button>
            </Box>
            {user.role !== "Student" && (
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#7A381E",
                  borderRadius: "8px",
                  "&:hover": {
                    backgroundColor: "#61291A",
                  },
                }}
                onClick={() => switchModals("directoryChannel", "audioChannel")}
              >
                Create Channel
              </Button>
            )}
          </Box>

          <TableContainer
            component={Paper}
            sx={{
              mt: 1,
              maxHeight: 500,
              overflowY: "auto",
              borderRadius: "10px",
              boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
            }}
          >
            <Table stickyHeader>
              <TableHead>
                <TableRow sx={{ backgroundColor: "#f0f2f5" }}>
                  <TableCell width="5%" sx={{ fontWeight: "bold" }}></TableCell>
                  <TableCell width="40%" sx={{ fontWeight: "bold" }}>
                    Channel Name
                  </TableCell>
                  <TableCell width="15%" sx={{ fontWeight: "bold" }}>
                    Privacy
                  </TableCell>
                  <TableCell width="15%" sx={{ fontWeight: "bold" }}>
                    Join
                  </TableCell>
                  <TableCell width="15%" sx={{ fontWeight: "bold" }}>
                    Share
                  </TableCell>
                  {user.role !== "Student" && (
                    <TableCell width="15%" sx={{ fontWeight: "bold" }}>
                      Edit
                    </TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredChannels.map((channel) => (
                  <React.Fragment key={channel.id}>
                    <TableRow hover>
                      <TableCell>
                        {channel.SubChannels?.length > 0 && (
                          <IconButton
                            onClick={() => toggleRow(channel.id)}
                            sx={{ color: "#494B83" }}
                          >
                            {expandedRows[channel.id] ? (
                              <ExpandLess />
                            ) : (
                              <ExpandMore />
                            )}
                          </IconButton>
                        )}
                      </TableCell>
                      <TableCell>
                        <Box display="flex" alignItems="center">
                          <Typography fontWeight="medium">
                            {channel.name}
                          </Typography>
                        </Box>
                      </TableCell>
                      <TableCell>
                        <Chip
                          icon={
                            channel.privacy === "public" ? (
                              <PublicIcon fontSize="small" />
                            ) : (
                              <PrivateIcon fontSize="small" />
                            )
                          }
                          label={channel.privacy}
                          size="small"
                          sx={{
                            backgroundColor:
                              channel.privacy === "public"
                                ? "#e3f2fd"
                                : "#fce4ec",
                            color:
                              channel.privacy === "public"
                                ? "#1976d2"
                                : "#c2185b",
                          }}
                        />
                      </TableCell>
                      <TableCell>
                        <Button
                          variant="contained"
                          startIcon={<JoinIcon />}
                          size="small"
                          onClick={() => handleJoinChannel(channel, "channel")}
                          sx={{
                            backgroundColor: "#494B83",
                            "&:hover": {
                              backgroundColor: "#363761",
                            },
                          }}
                        >
                          Join
                        </Button>
                      </TableCell>
                      <TableCell>
                        <Button
                          variant="contained"
                          startIcon={<ShareIcon />}
                          size="small"
                          onClick={() => handleShareChannel(channel)}
                          sx={{
                            backgroundColor: "#494B83",
                            "&:hover": {
                              backgroundColor: "#363761",
                            },
                          }}
                        >
                          Share
                        </Button>
                      </TableCell>
                      {user.role !== "Student" && (
                        <TableCell>
                          <Button
                            variant="contained"
                            startIcon={<EditIcon />}
                            size="small"
                            onClick={() => handleEditChannel(channel)}
                            sx={{
                              backgroundColor: "#494B83",
                              "&:hover": {
                                backgroundColor: "#363761",
                              },
                            }}
                          >
                            Edit
                          </Button>
                        </TableCell>
                      )}
                    </TableRow>

                    {channel.SubChannels?.length > 0 && (
                      <TableRow>
                        <TableCell
                          colSpan={6}
                          sx={{ padding: 0, borderBottom: "none" }}
                        >
                          <Collapse
                            in={expandedRows[channel.id]}
                            timeout="auto"
                            unmountOnExit
                          >
                            <Box
                              sx={{ p: 2, pl: 6, backgroundColor: "#f5f7fa" }}
                            >
                              <Typography
                                variant="subtitle2"
                                gutterBottom
                                sx={{ fontWeight: "bold", color: "#494B83" }}
                              >
                                Subchannels ({channel.SubChannels.length})
                              </Typography>
                              <Table size="small">
                                <TableHead>
                                  <TableRow>
                                    <TableCell
                                      width="40%"
                                      sx={{
                                        fontWeight: "bold",
                                        border: "none",
                                      }}
                                    >
                                      Subchannel Name
                                    </TableCell>
                                    <TableCell
                                      width="20%"
                                      sx={{
                                        fontWeight: "bold",
                                        border: "none",
                                      }}
                                    >
                                      Privacy
                                    </TableCell>
                                    <TableCell
                                      width="15%"
                                      sx={{
                                        fontWeight: "bold",
                                        border: "none",
                                      }}
                                      onClick={() =>
                                        handleJoinChannel(
                                          channel.SubChannels,
                                          "subchannel"
                                        )
                                      }
                                    >
                                      Join
                                    </TableCell>
                                    <TableCell
                                      width="15%"
                                      sx={{
                                        fontWeight: "bold",
                                        border: "none",
                                      }}
                                    >
                                      Share
                                    </TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {channel.SubChannels.map((sub) => (
                                    <TableRow key={sub.id} hover>
                                      <TableCell
                                        sx={{
                                          borderBottom: "1px solid #e0e0e0",
                                        }}
                                      >
                                        <Typography variant="body2">
                                          {sub.name}
                                        </Typography>
                                      </TableCell>
                                      <TableCell
                                        sx={{
                                          borderBottom: "1px solid #e0e0e0",
                                        }}
                                      >
                                        <Chip
                                          icon={
                                            sub.privacy === "public" ? (
                                              <PublicIcon fontSize="small" />
                                            ) : (
                                              <PrivateIcon fontSize="small" />
                                            )
                                          }
                                          label={sub.privacy}
                                          size="small"
                                          sx={{
                                            backgroundColor:
                                              sub.privacy === "public"
                                                ? "#e3f2fd"
                                                : "#fce4ec",
                                            color:
                                              sub.privacy === "public"
                                                ? "#1976d2"
                                                : "#c2185b",
                                          }}
                                        />
                                      </TableCell>
                                      <TableCell
                                        sx={{
                                          borderBottom: "1px solid #e0e0e0",
                                        }}
                                      >
                                        <IconButton
                                          size="small"
                                          sx={{ color: "#494B83" }}
                                          onClick={() =>
                                            handleJoinChannel(sub, "subchannel")
                                          }
                                        >
                                          <JoinIcon fontSize="small" />
                                        </IconButton>
                                      </TableCell>
                                      <TableCell
                                        sx={{
                                          borderBottom: "1px solid #e0e0e0",
                                        }}
                                      >
                                        <IconButton
                                          size="small"
                                          sx={{ color: "#494B83" }}
                                          onClick={() =>
                                            handleShareChannel(sub)
                                            // switchModals(
                                            //   "directoryChannel",
                                            //   "shareChannel"
                                            // )
                                          }
                                        >
                                          <ShareIcon fontSize="small" />
                                        </IconButton>
                                      </TableCell>
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            </Box>
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    )}
                  </React.Fragment>
                ))}
                {filteredChannels.length === 0 && (
                  <TableRow>
                    {loading ?
                      <TableCell colSpan={5} align="center" sx={{ py: 4 }}>
                        <LoadingButton />
                      </TableCell>
                      :
                      <TableCell colSpan={5} align="center" sx={{ py: 4 }}>
                        <Typography variant="subtitle1">
                          No channels found
                        </Typography>
                      </TableCell>
                    }
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
      </Dialog>

      <ShareChannelModal channelId={channelId} isPublic={isPublic} type={channelType} channelName={channelName} user={user} />
      <PasswordModal
        channel={channelForPasswordModal}
        channelType={channelType}
        user={user}
        setIsJoining={setIsJoining}
      />
    </>
  );
};

export default DirectoryChannelModal;
