import React, { Component } from "react";

import { withTranslation } from "react-i18next";
import "./TeacherDataReport.scss";
import Layout from "../../Layout/Layout";
import TeacherReportCard from "./TeacherReportCard/TeacherReportCard";

const url = process.env.REACT_APP_EP_URL;

let d = new Date();
class TeacherReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      slug: this?.props?.user?.slug,
      selectedClass: [],
      selectedColony: [],
      selectedInternetSpeed: [],
      LessonDate: [
        new Date(d.getFullYear(), d.getMonth() - 3, d.getDate(), 0, 0, 0, 0),
        new Date(d.getFullYear(), d.getMonth(), d.getDate(), 23, 59, 0, 0),
      ],
      allClassNames: null,
      colonyNames: [],
      allInternetSpeed: [],
      isEmployee: null,
      isScholarship: null,
      isPassing: null,
      filterBy: "Presente",
      filerts: {
        CF_1: null,
        CF_2: null,
        CF_3: null,
        CF_4: null,
        CF_5: null,
      },
      checkPhysical: null,
      loading: {
        attendance: false,
        chart: false,
      },
      isTabletOrMobileView: window.innerWidth <= 991,
      isTabletView: window.innerWidth > 767 && window.innerWidth <= 991,
      isMobileView: window.innerWidth <= 767,
    };

    this.handleResize = this.handleResize.bind(this);
  }

  handleResize() {
    this.setState({
      isTabletOrMobileView: window.innerWidth <= 991,
      isTabletView: window.innerWidth > 767 && window.innerWidth <= 991,
      isMobileView: window.innerWidth <= 767,
    });
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  render() {
    const { t } = this.props;

    return (
      <div className="teacher-report-page">
        <Layout isReportAvailable={true} isMobileView={this.state.isMobileView}>
          <TeacherReportCard
            user={this.props.user}
            isTabletOrMobileView={this.state.isTabletOrMobileView}
            isMobileView={this.state.isMobileView}
            isTabletView={this.state.isTabletView}
          />
        </Layout>
      </div>
    );
  }
}

export default withTranslation()(TeacherReport);
