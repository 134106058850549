import { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Typography,
  IconButton,
  Button,
  Divider,
  Box,
  InputAdornment,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { SubChannelPasswordsModal } from "../SubChannelPasswords";
import { ChannelPasswordModal } from "../ChannelPassword/ChannelPasswordModal";
import { useAudioChannel } from "../../../context/AudioChannelContext";
import { createChannel, updateChannel } from "../../../Assets/api/api";
import { toast } from "react-toastify";
export const OnDemandChannelsModal = ({ user }) => {
  const initialState = {
    // channelName: "",
    isPublic: true,
    subChannelsCount: 0,
    subChannelPrefix: "",
    subChannelsPublic: true,
    errors: {},
  };
  const shortSchoolName = user.schoolInfo.School.split(" ")
    .map((word) => word.charAt(0).toUpperCase())
    .join("");
  // const [channelName, setChannelName] = useState(initialState.channelName);
  const [isPublic, setIsPublic] = useState(initialState.isPublic);
  const [subChannelsCount, setSubChannelsCount] = useState(
    initialState.subChannelsCount
  );
  const [subChannels, setSubChannels] = useState([]);
  const [subChannelPrefix, setSubChannelPrefix] = useState(
    initialState.subChannelPrefix
  );
  const [subChannelsPublic, setSubChannelsPublic] = useState(
    initialState.subChannelsPublic
  );
  const [errors, setErrors] = useState(initialState.errors);
  const [loading, setLoading] = useState(false);
  const [apiError, setApiError] = useState("");
  const [password, setPassword] = useState("");
  const [isEditable, setisEditable] = useState(false);
  const [channelInputValue, setChannelInputValue] = useState("");
  const [subChannelInputValue, setSubChannelInputValue] = useState("");
  const [channelNamePrefix, setChannelNamePrefix] = useState(shortSchoolName);
  const [subChannelNamePrefix, setSubChannelNamePrefix] = useState(
    `${shortSchoolName}-${channelInputValue}`
  );
  const {
    modals,
    closeModal,
    switchModals,
    channelData,
    setChannelData,
    editChannelId,
    setEditChannelId,
  } = useAudioChannel();

  useEffect(() => {
    if (editChannelId) {
      console.log("editChannelId", channelData);
      channelData.forEach((channel) => {
        if (channel.id === editChannelId) {
          setisEditable(true);
          setChannelInputValue(channel.name.split("-").pop());
          setChannelNamePrefix(channel.name.split("-").shift());

          const [shortSchoolName, inputChannleName] = channel.name.split("-");
          setIsPublic(channel.privacy === "public");
          if (channel.totalSubChannels !== 0) {
            setSubChannelsCount(channel.totalSubChannels);
            // subchannel name set
            const subChannelParts = channel.SubChannels[0].name.split("-");
            const subChannelInputValue =
              subChannelParts[subChannelParts.length - 2];
            const subChannelNamePrefix = subChannelParts
              .slice(0, subChannelParts.length - 2)
              .join("-");
            setSubChannelInputValue(subChannelInputValue);
            setSubChannelNamePrefix(subChannelNamePrefix);
            setSubChannelsPublic(channel.SubChannels[0].privacy === "public");

            setSubChannels(
              channel.SubChannels.map((subChannel) => ({
                name: subChannel.name,
                password: subChannel.password,
              }))
            );
          }
        }
      });
    } else {
      setisEditable(false);
      resetState();
    }
  }, [editChannelId]);

  useEffect(() => {
    setSubChannelNamePrefix(`${shortSchoolName}-${channelInputValue}`);
  }, [channelInputValue]);

  const resetState = () => {
    // setChannelName(initialState.channelName);
    setIsPublic(initialState.isPublic);
    setSubChannelsCount(initialState.subChannelsCount);
    setSubChannelPrefix(initialState.subChannelPrefix);
    setSubChannelsPublic(initialState.subChannelsPublic);
    setErrors(initialState.errors);
    setLoading(false);
    setApiError("");
    setChannelInputValue("");
    setSubChannelInputValue("");
  };

  const handleClose = () => {
    closeModal("onDemand");
    resetState();
    setEditChannelId(null);
    setisEditable(false);
  };

  const validate = () => {
    let tempErrors = {};
    if (!channelInputValue || channelInputValue.length < 3)
      tempErrors.channelInputValue =
        "Channel Name must be at least 3 characters long.";
    if (subChannelsCount >= 1 && !subChannelInputValue.trim())
      tempErrors.subChannelPrefix = "Sub-channel prefix is required.";
    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleGenerate = async () => {
    if (!validate()) return;

    const payload = {
      channel: {
        name: `${channelNamePrefix}-${channelInputValue}`,
        privacy: isPublic ? "public" : "private",
        totalSubChannels: subChannelsCount,
        ...(isPublic ? {} : { password: password }),
      },
      subChannels: subChannels.map((ch) => ({
        name: `${ch.name}`,
        privacy: subChannelsPublic ? "public" : "private",
        ...(subChannelsPublic ? {} : { password: ch.password }),
      })),
    };
    console.log("payload of channel", payload);

    if (isEditable) {
      try {
        setLoading(true);
        setApiError("");

        await updateChannel(user.slug, editChannelId, payload);
        const updatedChannelData = channelData.map((channel) => {
          if (channel.id === editChannelId) {
            return {
              ...channel,
              ...payload.channel,
              SubChannels: payload.subChannels,
            };
          }
          return channel;
        });
        setChannelData([...updatedChannelData]);

        switchModals("onDemand", "directoryChannel");
      } catch (error) {
        console.error("API Error:", error);
        setApiError(error.message);
      } finally {
        setLoading(false);
      }
    } else {
      try {
        setLoading(true);
        setApiError("");

        await createChannel(user.slug || "ieidbhnzsflrqtef", payload);

        setChannelData([
          `${channelNamePrefix}-${channelInputValue}`,
          ...subChannels.map((ch) => ch.name),
        ]);
        switchModals("onDemand", "directoryChannel");
      } catch (error) {
        console.error("API Error:", error);
        setApiError(error.message);
      } finally {
        setLoading(false);
      }
    }
    setEditChannelId(null);

    return;
  };

  return (
    <>
      <Dialog
        open={modals.onDemand}
        fullWidth
        sx={{
          "& .MuiDialog-paper": {
            borderRadius: "0.75rem",
            maxWidth: "28rem",
            width: "100%",
          },
        }}
      >
        <DialogTitle sx={{ borderBottom: "2px solid #494B83" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="h6">On-demand Channels</Typography>
            <IconButton
              edge="end"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>

        <DialogContent sx={{ "&.MuiDialogContent-root": { padding: 3 } }}>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
            <TextField
              label="Channel Name"
              value={channelInputValue}
              id="outlined-start-adornment"
              size="small"
              onChange={(e) => setChannelInputValue(e.target.value)}
              error={!!errors.channelInputValue}
              helperText={errors.channelInputValue}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {channelNamePrefix}
                  </InputAdornment>
                ),
              }}
            />
            <FormControl>
              <FormLabel>Privacy</FormLabel>
              <RadioGroup
                value={isPublic ? "public" : "private"}
                onClick={(e) => {
                  setIsPublic(e.target.value === "public");
                  if (e.target.value === "private") {
                    if (channelInputValue) {
                      switchModals("onDemand", "channelPassword");
                    } else {
                      setErrors({
                        channelInputValue: "Channel Name is required.",
                      });
                    }
                  }
                }}
                sx={{ flexDirection: "row", gap: 2 }}
              >
                <FormControlLabel
                  value="public"
                  control={<Radio />}
                  label="Public"
                />
                <FormControlLabel
                  value="private"
                  control={<Radio />}
                  label="Private"
                />
              </RadioGroup>
              <Typography variant="caption" color="text.secondary">
                Private channels must have a password.
              </Typography>
            </FormControl>

            <Divider sx={{ borderColor: "#494B83" }} />
            <Typography variant="h6">Sub-channels</Typography>

            <TextField
              label="Number of Sub-channels (Up to 10)"
              type="number"
              value={subChannelsCount}
              size="small"
              onChange={(e) => {
                setSubChannelsCount(
                  Math.max(0, Math.min(10, parseInt(e.target.value) || 0))
                );
                setSubChannelsPublic(true);
              }}
              fullWidth
            />

            <TextField
              label="Prefix (e.g. Team)"
              value={subChannelInputValue}
              id="outlined-start-adornment"
              onChange={(e) => setSubChannelInputValue(e.target.value)}
              error={!!errors.subChannelPrefix}
              helperText={errors.subChannelPrefix}
              fullWidth
              size="small"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {subChannelNamePrefix}
                  </InputAdornment>
                ),
              }}
            />

            <FormControl>
              <FormLabel>Sub-channels Privacy</FormLabel>
              <RadioGroup
                value={subChannelsPublic ? "public" : "private"}
                onClick={(e) => {
                  setSubChannelsPublic(e.target.value === "public");
                  if (e.target.value === "private") {
                    if (subChannelsCount > 0) {
                      switchModals("onDemand", "subChannelPassword");
                    }
                  } else {
                    if (!isEditable) {
                      setSubChannels((prev) =>
                        prev.map((subChannel) => ({
                          ...subChannel,
                          password: "",
                        }))
                      );
                    }
                  }
                }}
                sx={{ flexDirection: "row", gap: 2 }}
              >
                <FormControlLabel
                  value="public"
                  control={<Radio />}
                  label="Public"
                />
                <FormControlLabel
                  value="private"
                  control={<Radio />}
                  label="Private"
                />
              </RadioGroup>
            </FormControl>

            {apiError && (
              <Typography color="error" variant="body2">
                {apiError}
              </Typography>
            )}
          </Box>
        </DialogContent>

        <Box
          sx={{
            display: "flex",
            borderTop: "2px solid #494B83",
            flexDirection: "column",
            gap: 1,
            p: 3,
          }}
        >
          <Button
            onClick={handleGenerate}
            fullWidth
            variant="contained"
            disabled={loading}
            sx={{
              backgroundColor: "#494B83",
              color: "white",
              "&:hover": { backgroundColor: "#3a3c69" },
            }}
          >
            {isEditable
              ? loading
                ? "Updating..."
                : "Update"
              : loading
              ? "Generating..."
              : "Generate"}
          </Button>
          <Button
            onClick={handleClose}
            fullWidth
            variant="contained"
            sx={{
              backgroundColor: "#494B83",
              color: "white",
              "&:hover": { backgroundColor: "#3a3c69" },
            }}
          >
            Close
          </Button>
        </Box>
      </Dialog>

      <SubChannelPasswordsModal
        subChannelsCount={subChannelsCount}
        subChannelPrefix={`${subChannelNamePrefix}-${subChannelInputValue}`}
        subChannels={subChannels}
        setSubChannels={setSubChannels}
        isEditable={isEditable}
      />
      <ChannelPasswordModal
        channelName={`${channelNamePrefix}-${channelInputValue}`}
        password={password}
        setPassword={setPassword}
        isEditable={isEditable}
      />
    </>
  );
};
