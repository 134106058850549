import { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  IconButton,
  TextField,
  Typography,
  FormHelperText,
  InputAdornment,
  Box,
  FormControl,
  FormLabel,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useAudioChannel } from "../../../context/AudioChannelContext";
import { compareChannelPassword } from "../../../Assets/api/api";
import { toast } from "react-toastify";

export const PasswordModal = ({ channel, channelType, user, setIsJoining }) => {
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const { modals, closeModal } = useAudioChannel();
  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  const handlePasswordChange = (value) => {
    setPassword(value);
  };

  const handleClose = () => {
    console.log("modal is closed");
    setPassword("");
    setError("");
    closeModal("passwordModal");
  };

  const handleJoinChannel = async () => {

    const payload = {
      id: channel.id,
      channelPassword: password,
      channelType: channelType,
    };
    try {
      const response = await compareChannelPassword(user.slug, payload);

      if (response.result) {
        setIsJoining(true);
        handleClose();
      } else {
        toast.error(response.message);
      }
    } catch (err) {
      toast.error(err);
    }
  };

  return (
    <Dialog
      open={modals.passwordModal}
      onClose={handleClose}
      fullWidth
      sx={{
        "& .MuiDialog-paper": {
          borderRadius: "0.75rem",
          maxWidth: "32rem",
          width: "100%",
        },
      }}
    >
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "1rem 1.5rem",
        }}
      >
        <Typography variant="h6">Join Channel: {channel?.name}</Typography>
        <IconButton
          edge="end"
          color="inherit"
          onClick={handleClose}
          aria-label="close"
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent sx={{ padding: "1.5rem" }}>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
          <FormControl fullWidth>
            <FormLabel sx={{ marginBottom: "0.5rem" }}>Password</FormLabel>
            <TextField
              type={showPassword ? "text" : "password"}
              value={password}
              onChange={(e) => handlePasswordChange(e.target.value)}
              placeholder="Enter channel password"
              fullWidth
              size="small"
              variant="outlined"
              error={Boolean(error)}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "#494B83",
                    borderWidth: "2px",
                  },
                  "&:hover fieldset": { borderColor: "#494B83" },
                  "&.Mui-focused fieldset": { borderColor: "#494B83" },
                },
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={togglePasswordVisibility} edge="end">
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {error && <FormHelperText error>{error}</FormHelperText>}
          </FormControl>
        </Box>
      </DialogContent>

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignContent: "end",
          gap: 1,
          p: 3,
        }}
      >
        <Button
          onClick={handleJoinChannel}
          variant="contained"
          disabled={!password || Boolean(error)}
          sx={{
            backgroundColor: "#494B83",
            color: "white",
            mx: 0,
            "&:hover": { backgroundColor: "#3a3c69" },
          }}
        >
          Join Channel
        </Button>
        <Button
          onClick={handleClose}
          variant="contained"
          sx={{
            backgroundColor: "#494B83",
            color: "white",
            mx: 0,
            "&:hover": { backgroundColor: "#3a3c69" },
          }}
        >
          Close
        </Button>
      </Box>
    </Dialog>
  );
};

export default PasswordModal;
