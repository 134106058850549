import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Box, Dialog, IconButton, InputAdornment, TextField, Typography } from "@mui/material";
import { useAudioChannel } from "../../../context/AudioChannelContext";
import { useEffect, useState } from "react";
import { compareChannelPassword } from "../../../Assets/api/api";
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

export const JoinChannelModal = ({ slug }) => {
  const { modals, closeModal, openChannelModal, setJoinChannelName, setIsJoining } = useAudioChannel();
  const queryParams = new URLSearchParams(window.location.search);
  const channelId = queryParams.get("channelid");
  const channelName = queryParams.get("channelname");
  const isPublic = queryParams.get("ispublic");
  const channelType = queryParams.get("type");

  useEffect(() => {
    if (channelId) {
      openChannelModal("joinChannelModal");
    }
  }, [channelId]);
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const handleJoinChannel = async () => {
    if (isPublic === "true") {
      setIsJoining(true);
      setJoinChannelName(channelName);
      closeModal("joinChannelModal");
    } else {
      const payload = {
        id: channelId,
        channelPassword: password,
        channelType: channelType,
      };
      console.log(" compare password payload", payload);
      try {
        const response = await compareChannelPassword(slug, payload);

        if (response.result) {
          setIsJoining(true);
          setJoinChannelName(channelName);
          closeModal("joinChannelModal");
        } else {
          setError(response.message);
        }
      } catch (error) {
        console.log("error message", error);
      }
    }
  }
  return (
    <Dialog
      open={modals.joinChannelModal}
      PaperProps={{
        sx: {
          borderRadius: 2,
          boxShadow: '0 8px 24px rgba(0, 0, 0, 0.15)',
          p: 3
        }
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          textAlign: 'center'
        }}
      >
        <IconButton
          onClick={() => closeModal("joinChannelModal")}
          size="small"
          sx={{
            position: 'absolute',
            top: 16,
            right: 16,
            color: 'grey.600'
          }}
        >
          <CloseIcon fontSize="small" />
        </IconButton>

        <Box sx={{ mt: 2 }}>
          <Typography variant="h5">
            Join Channel
          </Typography>
        </Box>

        {isPublic === "false" && (
          <Box sx={{ my: 3 }}>
            <Typography variant="body1" sx={{ mb: 2 }}>
              Enter the password to join the channel <strong>{channelName}</strong>.
            </Typography>
            <TextField
              label="Password"
              size="small"
              type={showPassword ? "text" : "password"}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              variant="outlined"

              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
          </Box>
        )}

        {isPublic === "true" && (
          <Typography variant="body1" sx={{ my: 3, width: "300px" }}>
            Would you like to join the channel <strong>{channelName}</strong>?
          </Typography>
        )}

        <Box sx={{ display: 'flex', gap: 6, justifyContent: 'center' }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <IconButton
              onClick={handleJoinChannel}
              sx={{
                backgroundColor: "#494B83",
                color: "white",
                p: 2,
                mb: 1,
                "&:hover": { backgroundColor: "#4F46E5" },
              }}
            >
              <CheckIcon fontSize="small" />
            </IconButton>
            <Typography variant="body2" fontWeight="medium">
              Join
            </Typography>
          </Box>

          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <IconButton
              onClick={() => closeModal("joinChannelModal")}
              sx={{
                backgroundColor: "#f5f5f5",
                color: "#666",
                p: 2,
                mb: 1,
                "&:hover": { backgroundColor: "#e0e0e0" },
              }}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
            <Typography variant="body2" fontWeight="medium">
              Decline
            </Typography>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};