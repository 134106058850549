import React, { useMemo } from "react";
import Chart from "react-apexcharts";

import {
  Button,
  FilesPdfColoredIcon,
  Flex,
  Text,
} from "@fluentui/react-northstar";
import { withTranslation } from "react-i18next";

const ProgressChart = ({ selectedChart, data, chartTypes, t, downloadPDF }) => {
  const translation = t("teacherReportCard");
  const commonTranslation = translation.common;
  const progressTranslation = translation.body;  

  const options = useMemo(() => {
    const getData = () => {
      switch (selectedChart) {
        case chartTypes.attendance:
          return {
            categories: data[chartTypes.attendance]?.categories ?? [],
            xAxisTitle: commonTranslation.months,
            yAxisTitle: progressTranslation.attendance.yAxisTitle,
            title: progressTranslation.attendance.title,
          };
        case chartTypes.assignment:
          return {
            categories: data[chartTypes.assignment]?.categories ?? [],
            xAxisTitle: commonTranslation.months,
            yAxisTitle: progressTranslation.assignment.yAxisTitle,
            title: progressTranslation.assignment.title,
          };
        case chartTypes.event:
          return {
            categories: data[chartTypes.event]?.categories ?? [],
            xAxisTitle: commonTranslation.months,
            yAxisTitle: progressTranslation.event.yAxisTitle,
            title: progressTranslation.event.title,
          };
        case chartTypes.feedback:
          return {
            categories: data[chartTypes.feedback]?.categories ?? [],
            xAxisTitle: commonTranslation.months,
            yAxisTitle: progressTranslation.feedback.yAxisTitle,
            title: progressTranslation.feedback.title,
          };
        case chartTypes.booking:
          return {
            categories: data[chartTypes.booking]?.categories ?? [],
            xAxisTitle: commonTranslation.months,
            yAxisTitle: progressTranslation.booking.yAxisTitle,
            title: progressTranslation.booking.title,
          };
      }
    };

    const { categories, xAxisTitle, yAxisTitle, title } = getData();

    return {
      chart: {
        type: "bar",
        stacked: false,
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "80%",
          endingShape: "rounded",
          borderRadius: 2,
        },
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        position: "bottom",
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      xaxis: {
        categories: categories ?? [],
        labels: {
          show: true,
          hideOverlappingLabels: true,
        },
        title: {
          text: xAxisTitle,
          offsetY: 95,
        },
      },
      yaxis: {
        title: {
          text: yAxisTitle,
        },
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val;
          },
        },
      },
      headerTitle: title,
    };
  }, [selectedChart, data]);

  return (
    <Flex fill column gap="gap.medium">
      <Text
        size="large"
        content={options.headerTitle}
        align="center"
        weight="semibold"
      />
      <Flex fill>
        <div style={{ flexGrow: 1 }}>
          <Chart
            options={options}
            series={
              data[chartTypes[selectedChart]]?.series?.map(
                ({ name: [chartProperty], ...rest }) => ({
                  ...rest,
                  name: [
                    progressTranslation[selectedChart].charts[chartProperty],
                  ],
                })
              ) ?? []
            }
            type="bar"
            width={"100%"}
            height={400}
          />
        </div>
        {downloadPDF && (
          <Button
            text
            iconOnly
            icon={<FilesPdfColoredIcon />}
            style={{ width: "fit-content" }}
            onClick={downloadPDF}
            title="Download report pdf"
          />
        )}
      </Flex>
    </Flex>
  );
};

export default withTranslation()(ProgressChart);
