import React, { useContext, createContext, useState, useEffect } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import { useLocation } from "react-router-dom";

import AttendanceImage from "../Assets/images/svg/LessonSelection.svg";
import EventImage from "../Assets/images/svg/events.svg";
import AssignmentImage from "../Assets/images/svg/AssignmentHome.svg";
import FeedbackImage from "../Assets/images/conversation.png";
import userSvg from "../Assets/images/svg/user.svg";

const chartContext = createContext();

export const useChartContext = () => {
  return useContext(chartContext);
};

const defaultReports = [
  {
    type: "attendance",
    header: "Attendance",
    content: "Attendance",
    image: AttendanceImage,
    reports: [
      {
        key: "reportByAttendanceSubject",
        title: "Report by subject",
        path: "attendance-report-by-subject",
        tag: "Subject",
      },
      {
        key: "reportByAttendanceStudent",
        title: "Report by student",
        path: "attendance-report-by-student",
        tag: "Student",
      },
      {
        key: "reportByAttendanceTeacher",
        title: "Report by teacher",
        path: "attendance-report-by-teacher",
        tag: "Teacher",
      },
      {
        key: "specialAttendanceReport",
        title: "Special report",
        path: "attendance-special-report",
        tag: "Special",
      },
      {
        key: "insightsReport",
        title: "Ai insights",
        path: "attendance-ai-insights",
        tag: "Special",
      },
    ],
  },
  {
    type: "assignment",
    content: "Assignment",
    image: AssignmentImage,
    reports: [
      {
        key: "reportByAssignmentSubject",
        title: "Report by subject",
        path: "assignment-report-by-subject",
        tag: "Subject",
      },
      {
        key: "reportByAssignmentStudent",
        title: "Report by student",
        path: "assignment-report-by-student",
        tag: "Student",
      },
      {
        key: "reportByAssignmentTeacher",
        title: "Report by teacher",
        path: "assignment-report-by-teacher",
        tag: "Teacher",
      },
    ],
  },
  {
    type: "event",
    content: "Event",
    image: EventImage,
    reports: [
      {
        key: "reportByCategory",
        title: "Report by category",
        path: "event-report-by-category",
        tag: "Category",
      },
      {
        key: "reportByTag",
        title: "Report by tag",
        path: "event-report-by-tag",
        tag: "Tag",
      },
    ],
  },
  {
    type: "feedback",
    content: "Feedback",
    image: FeedbackImage,
    reports: [
      {
        key: "reportByFeedback",
        title: "Feedback Report",
        path: "feedback-report",
        tag: "Feedback",
      },
    ],
  },
];

const END_POINT = process.env.REACT_APP_EP_URL;

const ChartContextProvider = ({ children, user }) => {
  const [selectedType, setSelectedType] = useState(null);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [isLoadingRBACData, setIsLoadingRBACData] = useState(false);
  const [reportOptions, setReportOptions] = useState([]);
  const [selectedReportPath, setSelectedReportPath] = useState("");
  const [isLoadingStudents, setIsLoadingStudents] = useState(false);
  const [students, setStudents] = useState([]);
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [isLoadingTeachers, setIsLoadingTeachers] = useState(false);
  const [teachers, setTeachers] = useState([]);
  const [selectedTeacher, setSelectedTeacher] = useState(null);
  const { pathname } = useLocation();

  const getAllStudent = (selectedClasses = []) => {
    setIsLoadingStudents(true);
    if (user.role.toLowerCase() === "teacher") {
      axios
        .get(
          `${END_POINT}/api/teacher/get-students-connected-with-teacher/${
            user.mail
          }${user.slug}&classNames=${selectedClasses.join(",")}`,
          {
            headers: {
              Authorization: "Bearer " + user.accessToken,
            },
          }
        )
        .then((res) => {
          const response = res?.data?.result || [];

          const students = response
            .map((student) => {
              return {
                header: student.name,
                image: student?.image ? student.image + user.SASToken : userSvg,
                content: student,
                key: student.email,
              };
            })
            .filter((value, index, self) => self.indexOf(value) === index);
          setIsLoadingStudents(false);
          setStudents(students);
        })
        .catch((err) => {
          setIsLoadingStudents(false);
          toast.error(err?.response?.data?.message);
          console.log(err);
        });
    } else {
      axios
        .get(
          `${END_POINT}/api/admin/get-students-info${
            user.slug
          }&classNames=${selectedClasses.join(",")}`,
          {
            headers: {
              Authorization: "Bearer " + user.accessToken,
            },
          }
        )
        .then((res) => {
          const response = res?.data?.result?.rows || [];

          const students = response
            .map((student) => {
              return {
                header: student.StudentName,
                image: student?.studPhoto?.Foto
                  ? student.studPhoto.Foto + user.SASToken
                  : userSvg,
                content: student,
                key: student.StudentEmailID,
              };
            })
            .filter((value, index, self) => self.indexOf(value) === index);
          setIsLoadingStudents(false);
          setStudents(students);
        })
        .catch((err) => {
          setIsLoadingStudents(false);
          toast.error(err?.response?.data?.message);
          console.log(err);
        });
    }
  };

  const getAllTeachers = (selectedClasses = []) => {
    setIsLoadingTeachers(true);
    axios
      .get(
        `${END_POINT}/api/admin/get-all-teachers${
          user.slug
        }&classNames=${selectedClasses.join(",")}`,
        {
          headers: {
            Authorization: "Bearer " + user.accessToken,
          },
        }
      )
      .then((res) => {
        const response = res?.data?.result || [];

        const teachers = response
          .map((teacher) => {
            return {
              header:
                teacher?.TeacherName || teacher.TeacherEmailID.split("@")[0],
              image: teacher?.UrlPhoto
                ? teacher.UrlPhoto + user.SASToken
                : userSvg,
              content: teacher,
              key: teacher.TeacherEmailID,
            };
          })
          .filter((value, index, self) => self.indexOf(value) === index);
        setIsLoadingTeachers(false);
        setTeachers(teachers);
      })
      .catch((err) => {
        setIsLoadingTeachers(false);
        toast.error(err?.response?.data?.message);
        console.log(err);
      });
  };

  const getRBACData = () => {
    setIsLoadingRBACData(true);
    const slug = user.slug;
    const token = user.accessToken;
    axios
      .get(`${END_POINT}/api/rbac/get-rbac-data${slug}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(({ data }) => {
        if (data?.data?.length) {
          let formattedData = data.data.reduce((acc, curr) => {
            acc[curr.area] = acc[curr.area] ?? [];

            acc[curr.area].push(curr);
            return acc;
          }, {});
          formattedData = Object.entries(formattedData).map(
            ([type, reportOptions]) => ({ type, reportOptions })
          );

          let formattedReports = [];

          let role = user?.role?.toLowerCase();

          if (role === "superadmin") {
            formattedReports = defaultReports;
          } else if (["admin", "teacher", "student"].includes(role)) {
            formattedReports = defaultReports.reduce((acc, curr) => {
              const matchedReports = formattedData.find(
                (report) => report.type === curr.type
              );

              if (matchedReports) {
                let reports = curr.reports.filter((item) =>
                  matchedReports.reportOptions.some(
                    ({ option, ...rest }) => option === item.path && rest[role]
                  )
                );
                if (reports.length) {
                  acc.push({
                    ...curr,
                    reports,
                  });
                }
              }
              return acc;
            }, []);
          }

          setReportOptions(formattedReports);
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      })
      .finally(() => {
        setIsLoadingRBACData(false);
      });
  };

  useEffect(() => {
    let selectedReport = reportOptions.find((item) =>
      item.reports.some((report) => `/dashboard/${report.path}` === pathname)
    );

    if (selectedReport || selectedType) {
      if (!document.querySelector(".chart-layout.h-cls")) {
        document.querySelector(".chart-layout")?.classList?.add("h-cls");
      }
      if (!selectedType) {
        setSelectedType(selectedReport);
      }
    }
  }, [pathname, selectedType, reportOptions]);

  useEffect(() => {
    setIsFullscreen(!!document.fullscreenElement);
    document.addEventListener("fullscreenchange", () => {
      setIsFullscreen(!!document.fullscreenElement);
    });

    let isSubscribed = false;
    let isExpire = false;

    if (user?.dbData?.subscription?.dashboard) {
      isSubscribed = true;
    }

    if (user?.dbData?.dayRemaining <= 0) {
      isExpire = true;
    }
    if (
      (isSubscribed && !isExpire) ||
      user.slug === "?slug=acvpreprod" ||
      user.slug === "?slug=acvapp"
    )
      getRBACData();
  }, []);

  return (
    <chartContext.Provider
      value={{
        reportOptions,
        selectedType,
        setSelectedType,
        isFullscreen,
        setIsFullscreen,
        isLoadingRBACData,
        selectedReportPath,
        setSelectedReportPath,
        getAllStudent,
        isLoadingStudents,
        students,
        selectedStudent,
        setSelectedStudent,
        getAllTeachers,
        isLoadingTeachers,
        teachers,
        selectedTeacher,
        setSelectedTeacher,
      }}
    >
      {children}
    </chartContext.Provider>
  );
};

export default ChartContextProvider;
