import React, { useMemo } from "react";

import { Redirect, Route, useHistory } from "react-router-dom";

import { EventContext } from "../context/EventContext";
import Account from "../pages/Account";
import Assignment from "../pages/Assignment/Assignment";
import StudentAssignmentHome from "../pages/Assignment/Student/Assignments/AssignmentHome";
import Attendance from "../pages/Attendance/Attendance";
import Events from "../pages/Events";
import DiscoverEvents from "../pages/Events/DiscoverEvents";
import EventFavourites from "../pages/Events/Favourites";
import Registrations from "../pages/Events/Registrations";
import EditEvent from "../pages/Events/edit";
import EventDetails from "../pages/Events/eventDetails";
import NewEvent from "../pages/Events/new";
import Feedback from "../pages/Feedback/Feedback";
import History from "../pages/History/AttendanceHistory";
import { LessonImagePicker } from "../pages/LessonImagePicker";
import Settings from "../pages/Settings/Settings";
import Startup from "../pages/Startup/Startup";
import TabConfig from "../pages/TabConfig";
import Whiteboard from "../pages/Whiteboard/Whiteboard";

import { BookingContext } from "../context/BookingContext";
import { FeedBackContext } from "../context/FeedbackContext";
import { TutorRequestContext } from "../context/TutorRequestContext";
import AiTutor from "../pages/AiTutor";
import AiDiscover from "../pages/AiDiscover";
import AiVideos from "../pages/AiVideos";
import Booking from "../pages/Booking";
import ScheduleMeeting from "../pages/Booking/Components/ScheduleMeeting/ScheduleMeeting";
import TutorProfile from "../pages/Booking/Components/TutorProfile/TutorProfile";
import ChartsWrapper from "../pages/Charts";
import { BundleEvents, Bundles as EventBundles } from "../pages/Events/Bundles";
import PageNotFound from "../pages/PageNotFound/PageNotFound";
import Student from "../pages/Student/Student";

const generateRoutes = (data, role, path) => {
  let redirectToPageNotFound = false;

  redirectToPageNotFound =
    ((path === "/attendance" || path === "/attendance/*") &&
      !data?.selectedExtension?.attendance) ||
    ((path === "/assignments" || path === "/assignments/*") &&
      !data?.selectedExtension?.assignment) ||
    (path === "/whiteboard" && !data?.selectedExtension?.whiteBoard) ||
    (path === "/dashboard" && !data?.selectedExtension?.dashboard) ||
    ((path === "/feedback" || path === "/feedback/*") &&
      !data?.selectedExtension?.feedback) ||
    ((path === "/events" || path === "/events/*") &&
      !data?.selectedExtension?.booking) ||
    ((path === "/booking" || path === "/booking/*") &&
      !data?.selectedExtension?.booking) ||
    (path === "/ai-tutor" && !data?.selectedExtension?.aiTutor);

  let redirectToPath = null;

  // Check conditions for redirection
  if (data?.selectedExtension?.attendance) {
    redirectToPath = "/attendance";
  } else if (data?.selectedExtension?.dashboard) {
    redirectToPath = "/dashboard";
  } else if (data?.selectedExtension?.assignment) {
    redirectToPath = "/assignments";
  } else if (data?.selectedExtension?.whiteBoard) {
    redirectToPath = "/whiteboard";
  } else if (data?.selectedExtension?.feedback) {
    redirectToPath = "/whiteboard";
  } else if (data?.selectedExtension?.events) {
    redirectToPath = "/events";
  } else if (data?.selectedExtension?.booking) {
    redirectToPath = "/booking";
  } else if (data?.selectedExtension?.aiTutor) {
    redirectToPath = "/ai-tutor";
  } else {
    redirectToPath = "/account";
  }

  // console.log("redirectToPageNotFound", redirectToPageNotFound);

  const commonRoutes = [
    {
      path: "/whiteboard",
      render: (props) => <Whiteboard {...data} {...props} />,
    },
    {
      path: "/settings/*",
      render: (props) => <Settings {...data} {...props} />,
    },
    { path: "/config", render: (props) => <TabConfig {...data} {...props} /> },
    {
      path: "/dashboard",
      render: (props) => <ChartsWrapper {...data} {...props} />,
    },
    // { path: "/docs", render: (props) => <Docs {...props} /> },
    {
      path: "/events/discover",
      render: (props) => (
        <EventContext {...data}>
          <DiscoverEvents {...data} {...props} />{" "}
        </EventContext>
      ),
    },
    {
      path: "/events/my-events",
      render: (props) => (
        <EventContext {...data}>
          <Events {...data} {...props} />
        </EventContext>
      ),
    },
    {
      path: "/events/favourites",
      render: (props) => (
        <EventContext {...data}>
          <EventFavourites {...data} {...props} />
        </EventContext>
      ),
    },
    {
      path: "/event/new",
      render: (props) => (
        <EventContext {...data}>
          <NewEvent {...data} {...props} />
        </EventContext>
      ),
    },
    {
      path: "/events/registrations",
      render: (props) => (
        <EventContext {...data}>
          <Registrations {...data} {...props} />
        </EventContext>
      ),
    },
    {
      path: "/event/edit/:id",
      render: (props) => <EditEvent {...data} {...props} />,
    },
    {
      path: "/event/details/:id",
      render: (props) => <EventDetails {...data} {...props} />,
    },
    {
      path: "/events/bundles",
      render: (props) => (
        <EventContext {...data}>
          <EventBundles {...data} {...props} />
        </EventContext>
      ),
    },
    {
      path: "/events/bundles/:bundleId",
      render: (props) => (
        <EventContext {...data}>
          {" "}
          <BundleEvents {...data} {...props} />
        </EventContext>
      ),
    },
    {
      path: "/feedback/*",
      render: (props) => (
        <FeedBackContext {...data}>
          <Feedback {...data} {...props} />
        </FeedBackContext>
      ),
    },
    { path: "/account", render: (props) => <Account {...data} {...props} /> },
    {
      path: "/booking",
      render: (props) => (
        <BookingContext {...data}>
          <Booking {...data} {...props} />
        </BookingContext>
      ),
    },
    {
      path: "/tutor/:mail/:role",
      render: (props) => (
        <BookingContext {...data}>
          <TutorProfile {...data} {...props} />
        </BookingContext>
      ),
    },
    {
      path: "/schedule-meeting/:mail/:role",
      render: (props) => (
        <BookingContext {...data}>
          <ScheduleMeeting {...data} {...props} />
        </BookingContext>
      ),
    },
    {
      path: "/edit-schedule-meeting/:mail/:role/:slotId",
      render: (props) => (
        <BookingContext {...data}>
          <ScheduleMeeting {...data} {...props} />
        </BookingContext>
      ),
    },
    {
      path: "/switch-db",
      render: (props) => <Redirect to="/" />,
    },
    {
      path: "/page-not-found",
      render: (props) => <PageNotFound {...data} {...props} />,
    },
    {
      path: "/auth",
      render: (props) => <Redirect to="/" />,
    },
    {
      path: "/",
      render: (props) => <Redirect to={redirectToPath} />,
    },
    {
      path: "/ai-tutor",
      render: (props) => <AiTutor {...data} {...props} />,
    },
    {
      path: "/ai-discover",
      render: (props) => <AiDiscover {...data} {...props} />,
    },
    {
      path: "/ai-videos",
      render: (props) => <AiVideos {...data} {...props} />,
    },
  ];

  const teacherRoute = [
    ...commonRoutes,
    {
      path: "/assignments",
      render: (props) => <Assignment {...data} {...props} />,
    },
    {
      path: "/attendance",
      render: (props) => <Attendance {...data} {...props} />,
    },
    { path: "/history", render: (props) => <History {...data} {...props} /> },
  ];

  const superAdminRoute = [
    ...teacherRoute,
    {
      path: "/settings",
      render: (props) => <Settings {...data} {...props} />,
    },
    {
      path: "/start-up",
      render: (props) => {
        if (
          data.user.slug === "?slug=acvpreprod" ||
          data.user.slug === "?slug=acvapp"
        ) {
          return <Redirect to="/page-not-found" />;
        }
        return <Startup {...data} {...props} />;
      },
    },
    {
      path: "/start-up/*",
      render: (props) => {
        if (
          data.user.slug === "?slug=acvpreprod" ||
          data.user.slug === "?slug=acvapp"
        ) {
          return <Redirect to="/page-not-found" />;
        }
        return <Startup {...data} {...props} />;
      },
    },
    {
      path: "/lesson-image-picker",
      render: (props) => <LessonImagePicker {...data} {...props} />,
    },
  ];

  const studentRoute = [
    {
      path: "/assignments",
      render: (props) => <StudentAssignmentHome {...data} {...props} />,
    },
    {
      path: "/attendance",
      render: (props) => (
        <Redirect
          {...data}
          {...props}
          to={
            data?.selectedExtension?.assignment
              ? "/assignments"
              : data?.selectedExtension?.whiteBoard
              ? "/whiteboard"
              : data?.selectedExtension?.feedback
              ? "/feedback/submit-feedback"
              : data?.selectedExtension?.events
              ? "/events/my-events"
              : "/account"
          }
        />
      ),
    },
  ];

  const parentRoutes = [
    {
      path: "/parent/student",
      render: (props) => <Student {...data} {...props} />,
    },
    {
      path: "/feedback/*",
      render: (props) => (
        <FeedBackContext {...data}>
          <Feedback {...data} {...props} />
        </FeedBackContext>
      ),
    },
    {
      path: "/attendance",
      render: (props) => <Redirect to="/parent/student" />,
    },
    {
      path: "/ai-tutor",
      render: (props) => <AiTutor {...data} {...props} />,
    },
    {
      path: "/ai-discover",
      render: (props) => <AiDiscover {...data} {...props} />,
    },
    {
      path: "/ai-videos",
      render: (props) => <AiVideos {...data} {...props} />,
    },
    { path: "/account", render: (props) => <Account {...data} {...props} /> },
    {
      path: "/auth",
      render: (props) => <Redirect to="/parent/student" />,
    },
    {
      path: "/",
      render: (props) => <Redirect to="/parent/student" />,
    },
    {
      path: "/events/discover",
      render: (props) => (
        <EventContext {...data}>
          <DiscoverEvents {...data} {...props} />{" "}
        </EventContext>
      ),
    },
    {
      path: "/event/details/:id",
      render: (props) => <EventDetails {...data} {...props} />,
    },
    {
      path: "/events/registrations",
      render: (props) => (
        <EventContext {...data}>
          <Registrations {...data} {...props} />
        </EventContext>
      ),
    },
  ];

  const externalTutorRoutes = [
    { path: "/account", render: (props) => <Account {...data} {...props} /> },
    {
      path: "/booking",
      render: (props) => (
        <BookingContext {...data}>
          <Booking {...data} {...props} />
        </BookingContext>
      ),
    },
    {
      path: "/tutor/:mail/:role",
      render: (props) => (
        <BookingContext {...data}>
          <TutorProfile {...data} {...props} />
        </BookingContext>
      ),
    },
    {
      path: "/auth",
      render: (props) => <Redirect to="/booking" />,
    },
    {
      path: "/",
      render: (props) => <Redirect to="/booking" />,
    },
  ];

  const parentPlusExternalTutorRoutes = [
    ...parentRoutes,
    ...externalTutorRoutes,
  ];

  switch (role) {
    case "Student":
      return redirectToPageNotFound ? [] : [...commonRoutes, ...studentRoute];
    case "Teacher":
      return redirectToPageNotFound ? [] : [...teacherRoute];
    case "Parent":
      return redirectToPageNotFound
        ? []
        : data.user.isTutor
        ? [...parentPlusExternalTutorRoutes]
        : [...parentRoutes];
    case "ExternalTutor":
      return redirectToPageNotFound ? [] : [...externalTutorRoutes];
    default:
      return redirectToPageNotFound ? [] : [...superAdminRoute];
  }
};

const PageRouter = (propsData) => {
  const role = propsData?.user?.role;
  const path = window.location.pathname;
  const history = useHistory();

  const routes = useMemo(() => {
    const _routes = generateRoutes(propsData, role, path);

    if (_routes.length === 0) {
      history.push("/page-not-found");
      return [];
    }

    return _routes;
  }, [propsData, role, path]);

  return (
    <TutorRequestContext {...propsData}>
      {routes.map((route, index) => (
        <Route
          key={index}
          path={route.path}
          exact
          render={(props) => route.render(props)}
        />
      ))}
      <Route
        path="/page-not-found"
        render={(props) => <PageNotFound {...propsData} {...props} />}
      />
    </TutorRequestContext>
  );
};

export default PageRouter;
