import {
  Button,
  CloseIcon,
  Dialog,
  DownloadIcon,
  Flex,
  Input,
  Pill,
  SearchIcon,
  ShareAltIcon,
  Text,
  UserFriendsIcon,
} from "@fluentui/react-northstar";
import { useCallback, useEffect, useRef, useState } from "react";
import { useAudioChannel } from "../../../context/AudioChannelContext";
import "../../../pages/Events/Components/ShareEvent/ShareEvent.scss";
import { getQRCode } from "../../../utils/generateQRCode";
import { JoinChannelModal } from "../JoinChannelViaShare";
import { toast } from "react-toastify";
import axios from "axios";

export const ShareChannelModal = ({ channelId, isPublic, type, channelName, user }) => {
  const channelLongUrl = `${process.env.REACT_APP_URI}/assignments?channelname=${channelName}&channelid=${channelId}&ispublic=${isPublic}&type=${type}`;
  const [qrCodeImage, setQrCodeImage] = useState("");
  const [emailInputText, setEmailInputText] = useState("");
  const [emails, setEmails] = useState(['']);
  const [isInvalidEmailText, setIsInvalidEmailText] = useState(false);
  const [isEmailsExpanded, setIsEmailsExpanded] = useState(false);
  const { modals, closeModal, channelData } = useAudioChannel();
  const [loading, setLoading] = useState(false);
  const emailsRef = useRef(emails);
  const msg = localStorage.getItem("msg");
  const accessToken = JSON.parse(msg).access_token

  useEffect(() => {
    emailsRef.current = emails;
  }, [emails])
  const qrCodeGenerator = () => {
    const qrValue = getQRCode(channelLongUrl);
    return qrValue;
  };
  const API_URL = process.env.REACT_APP_EP_URL;
  useEffect(() => {
    if (modals.shareChannel) {
      try {
        setEmails([]);
        setEmailInputText("");
        const qrValue = qrCodeGenerator();
        if (!qrValue) return;
        setQrCodeImage(qrValue);
      } catch (error) { }
    }
  }, [modals.shareChannel]);

  const downloadQRImage = useCallback(() => {
    const elm = document.createElement("a");
    elm.href = qrCodeImage;
    elm.download = ` CommanQR : ""
        }`;
    elm.click();
  }, [qrCodeImage]);

  const onAddEmailHandler = () => {
    let emailInputList = emailInputText?.length
      ? [
        ...new Set(
          emailInputText
            .toLowerCase()
            .split(";")
            .filter((email) => email)
        ),
      ]
      : [];
    if (!emailInputList.length) {
      return;
    }
    const emailRegex = /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/;
    let isValid = true;
    emailInputList.forEach((emailText) => {
      if (!emailRegex.test(emailText)) {
        isValid = false;
      }
    });

    if (emailInputText?.includes(",")) {
      isValid = false;
    }

    if (isValid) {
      setEmails((prev) => [...new Set([...prev, ...emailInputList])]);
      setEmailInputText("");
    } else {
      setIsInvalidEmailText(true);
    }
  };

  const shareChannelLinkHandler = async () => {
    if (channelId) {
      try {
        navigator.clipboard.writeText(channelLongUrl);
        toast.success("Share Channel link successfully.", {
          closeOnClick: true,
        });
      } catch {
        toast.error("sharing link error found.", {
          closeOnClick: true,
        });
      }
    }
    setEmailInputText("");
    setEmails([]);
    setIsInvalidEmailText(false);

  };

  const sendEmailInvitationHandler = async () => {
    // setEmails([]);
    try {
      setLoading(true);

      const payload = {
        emails,
        link: channelLongUrl,
        title: channelName,
      };
      console.log("api calling");
      console.log("user", user, user.accessToken, user.slug);

      const { data } = await axios({
        method: "POST",
        url: `${API_URL}/api/channel/send-invitation/:${channelName}${user.slug}`,
        data: payload,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      if (data) {
        if (
          data.status?.toLowerCase() === "success" &&
          typeof data.message === "string"
        ) {
          toast.success(data.message);
          setEmails([]);
        } else if (data.status?.toLowerCase() === "error") {
          toast.error(
            typeof data.message === "string"
              ? data.message
              : "Invitation FailF"
          );
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    closeModal("shareChannel");
    setEmails([]);
    setEmailInputText("");
  };
  return (
    <>
      <Dialog
        className="share-event-dialog"
        open={modals.shareChannel}
        onClose={handleClose}
        content={
          <div className="share-event-wrapper">
            <div className="share-event-tabs">
              <ol className="share-event-tab-list">
                <li className="share-event-tab-list-item active">Share</li>
              </ol>
              <div className="share-event-tab-content">
                <div className="share-event-link">
                  <div className="share-event-link-container">
                    <div className="email-invite-wrapper">
                      <div className="input-wrapper">
                        <Flex column>
                          <Input
                            className="email-input"
                            fluid
                            icon={<SearchIcon />}
                            value={emailInputText}
                            onChange={(e, { value }) => {
                              setIsInvalidEmailText(false);
                              setEmailInputText(value);
                            }}
                            clearable
                            label={"Email(s) to invite"}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                onAddEmailHandler();
                              }
                            }}
                            error={isInvalidEmailText}
                          />
                          <p>
                            You can send multiple separating accounts by
                            semicolons(;).
                          </p>
                        </Flex>
                      </div>
                      <div className="emails-wrapper">
                        <Flex wrap>
                          {(emails.length > 1
                            ? isEmailsExpanded
                              ? emails
                              : emails.slice(0, 1)
                            : emails
                          ).map((item, index) => {
                            return (
                              <Pill
                                actionable
                                key={index}
                                onDismiss={() => {
                                  if (emails.length === 0) {
                                    setEmails([]);
                                    return;
                                  } else {
                                    setEmails((prev) =>
                                      prev.filter((data) => data !== item)
                                    );
                                  }
                                }}
                                style={{
                                  minWidth: "initial",
                                  backgroundColor: "#6264A7",
                                  color: "#fff",
                                }}
                              >
                                {item}
                              </Pill>
                            );
                          })}
                        </Flex>
                        {emails.length > 1 && (
                          <Flex hAlign="end">
                            <Button
                              primary
                              onClick={() =>
                                setIsEmailsExpanded((prev) => !prev)
                              }
                            >
                              {isEmailsExpanded
                                ? "showLess"
                                : "showAll"}
                            </Button>
                          </Flex>
                        )}
                      </div>
                      <div className="action-button-wrapper">
                        <Flex
                          space="between"
                          className="share-event-action-buttons"
                        >
                          <Button
                            primary
                            icon={<ShareAltIcon />}
                            content={"shareLink"}
                            onClick={() => shareChannelLinkHandler()}
                          />
                          <Button
                            primary
                            icon={<UserFriendsIcon />}
                            disabled={!emails.length}
                            content={"Invite"}
                            onClick={() => sendEmailInvitationHandler()}
                          />
                        </Flex>
                      </div>
                    </div>
                    <div className="qr-code-action-wrapper">
                      <div className="qr-image-wrapper">
                        <img src={qrCodeImage} alt="QR Code" />
                      </div>
                      <div>
                        <Button
                          primary
                          icon={<DownloadIcon />}
                          onClick={downloadQRImage}
                          content={
                            <>
                              <Text>{"Download"} </Text>
                              <Text className="download-extra-text">
                                {"QR Code"}
                              </Text>
                            </>
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
        header={<div>{"Share your Channel"}</div>}
        headerAction={{
          icon: <CloseIcon />,
          title: "close",
          onClick: () => {
            handleClose();
          },
        }}
        styles={{ maxWidth: "700px" }}
      />
      <JoinChannelModal slug={user.slug} />
    </>
  );
};
